<template>
  <Header />
  <div class="container">
    <div class="row">
      <Sidebar
        class="col-md-3 col-lg-2"
        :items="['Início', 'Cadastros', '- Usuário', '- Comunidade']"
        :routes="['/admin', '/admin/registers', '/admin/new', '/admin/comunity']"
      />
      <div class="main col-md-9 col-lg-10 px-md-4">
                  <h4 >CADASTRO DE COMUNIDADE</h4>
        <form>
          <div class="row">
            <div class="col-md-4">
              <label for="name" class="form-label text"> Nome </label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="comunity.name"
              />
            </div>
            <div class="col-md-2 d-flex align-items-end">
              <button
                type="button"
                class="btn button"
                @click="saveComunity"
              >
                Adicionar
              </button>
            </div>
          </div>
        </form>
        <h5 class="mt-3 text">Comunidades</h5>
        <table class="table">
          <tbody>
            <tr v-for="(item, id) in communities" :key="id">
              <td>
                {{ item.attributes.Name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Comunity from "@/services/comunity";
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";

/** Validations */
import CommomValidation from "@/validation/CommomValidation";
import ComunityValidation from "@/validation/Comunity";

export default {
  name: "Comunity",
  components: { Sidebar, Header },
  data() {
    return {
      communities: [],
      comunity: {
        id: null,
        name: null,
      },
    };
  },
  methods: {
    validate() {
      if (!CommomValidation.isFieldsAreNull(this.comunity)) {
        return ComunityValidation.validate(this.comunity);
      }

      return null;
    },
    saveComunity() {
      const errors = this.validate();

      if (errors && errors.length == 0) {
        Comunity.saveOrUpdate(this.comunity)
          .then(() => {
            this.getAllCommunities();
          })
          .catch((error) => {
            console.log(`Erro ao adicionar comunidade: ${error}`);
          });

          this.clear();
      }
    },
    getAllCommunities() {
      Comunity.all()
        .then((response) => {
          if (response.data.data) {
            // response.data.data.forEach(comunity => {
            //   this.comunity.id = comunity.id;
            //   this.comunity.name = comunity.attributes.Name;
            //   this.communities.push(JSON.parse(JSON.stringify(this.comunity)));
            // })

            this.communities = response.data.data;
            this.communities.sort((a, b) => a.attributes.Name.localeCompare(b.attributes.Name));

            this.clear();
          }
        })
        .catch((error) => {
          console.log(`Erro ao listar comunidades: ${error}`);
        })
    },
    remove(id) {
      Comunity.remove(id)
        .then((response) => {
          if (response.data.data) 
            this.getAllCommunities();
        })
        .catch((error) => {
          console.log("Erro ao remover Comunidate: " + error);
        });
    },
    update(id) {
      Comunity.findOne(id)
        .then((response) => {
          if (response.data.data) 
            this.comunity.id = response.data.data.id;
            this.comunity.name = response.data.data.attributes.Name;
        })
        .catch((error) => {
          console.log("Erro ao remover Comunidate: " + error);
        });
    },
    clear() {
      this.comunity.id = null;
      this.comunity.name = null;
    },
  },
  mounted() {
    this.getAllCommunities();
  }
}
</script>

<style scoped>
.container {
  min-width: 100%;
}

.text {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #828282;
}

.main {
  padding: 20px 20px;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: rgb(223, 232, 240);
  min-height: 80vh;
}

label {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.button {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: #f8af2f;
}

a {
  text-decoration: none;
}

tr:hover {
  border: 1px solid #f8af2f;
}

.table {
  max-width: 90%;
}
</style>
